<template>
    <div>
      <footer class="text-center text-lg-start text-white" style="background-color: #CF0020;">
  
        <section class="container py-4">
          <div class="row" style="margin-top: 20px;">
            <div class="col-lg-5 col-md-12 mb-4 mb-md-0">
              <h6 class="text-uppercase fw-bold">
                <img src="../assets/img/logo-white.svg" style="max-width: 190px;" class="img-fluid" alt="Logo" />
              </h6>
              <p style="margin-top: 10px; font-weight: 600;">
                <i class="fa fa-map-marker" style="margin-right: 5px;"></i> Chaussée de Wavre 1663, 1160 Auderghem, Bruxelles, Belgium
              </p>
            </div>
  
            <div class="col-lg-2 col-md-4 mb-4 mb-md-0 text-center">
              <p>
                <router-link to="/" class="text-white">{{ translate('home') }}</router-link>
              </p>
              <p>
                <router-link to="/" class="text-white">{{ translate('track_shipment') }}</router-link>
              </p>
            </div>
  
            <div class="col-lg-2 col-md-4 mb-4 mb-md-0 text-center">
              <p>
                <router-link to="/" class="text-white">{{ translate('contact_us') }}</router-link>
              </p>
              <p>
                <router-link to="/about" class="text-white">{{ translate('about_us') }}</router-link>
              </p>
            </div>
  
            <div class="col-lg-3 col-md-4 mb-4 mb-md-0 text-center">
              <div class="mt-3">
                <a href="#" class="text-white me-4">
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a href="#" class="text-white me-4">
                  <i class="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="#" class="text-white me-4">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a href="https://be.linkedin.com/company/cit-express-logistics-sa" class="text-white">
                  <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
  
        <!-- Terms & Privacy Section -->
        <div class="text-center p-3" style="background-color: #940B20">
          <a class="text-white d-inline-block ms-2 me-2" href="">{{ translate('terms_of_use') }}</a> |
          <a class="text-white d-inline-block ms-2 me-2" href="">{{ translate('privacy_policy') }}</a> |
          <a class="text-white d-inline-block ms-2 me-2" href="">{{ translate('accessibility_statement') }}</a>
        </div>
  
        <!-- Copyright Section -->
        <div class="text-center p-3" style="background-color: #940B20">
          &copy; 2023
          <a class="text-white" href="">CIT Express | {{ translate('all_rights_resereved') }}</a>
        </div>
      </footer>
    </div>
  </template>
  
  
  
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import en from '../en.js';
  import fr from '../fr.js';
  import nl from '../nl.js';
  
  
  export default {
    name: 'Footer',
    mixins: [en, fr, nl],
    computed: {
      lang() {
        return this.$store.state.lang
      },
    },
    methods: {
      translate(prop) {
        return this[this.lang][prop];
      },
    }
  }
  </script>
