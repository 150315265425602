<template>
    <nav class="navbar navbar-expand-lg sm-body-tertiary" :class="{ 'red-background': hasScrolled, 'navbar': true }"
        style="padding-bottom: 0px; position: fixed; width: 100%; box-shadow: 0 2px 2px -2px gray;">
        <div class="container">
            <div class="d-flex justify-content-between ">
                <router-link class="navbar-brand align-self-start" to="/">
                    <template v-if="!hasScrolled">
                        <img class="navbar-brand align-self-start" src="../assets/img/logonavbar.svg" style="margin: -5px;">
                    </template>
                    <template v-else>
                        <img class="navbar-brand align-self-start" src="../assets/img/logo_transparent.svg" style="margin: -5px;">
                    </template>
                </router-link>
                <button class="navbar-toggler align-self-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation" style="border: 0px solid white; margin-bottom: 15px;">
                    <template v-if="!hasScrolled">
                        <img src="../assets/img/menu_toolbar.svg" style="transform: scale(1.5);">
                    </template>
                    <template v-else>
                        <img src="../assets/img/menu_toolbar_white.svg" style="transform: scale(1.5);">
                    </template>
                </button>
            </div>

            <div class=" collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto ">
                    <li class="nav-item custom-nav-item">
                        <router-link style="font-size: 20px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page" to="/">{{ translate('home') }}</router-link>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/secteurs-livraison" style="font-size: 20px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page">{{ translate('nos_service') }}</router-link>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/contact" style="font-size: 20px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page">{{ translate('contact_us') }}</router-link>
                    </li>
                    <li class="nav-item custom-nav-item">
                        <router-link to="/about" style="font-size: 20px; font-weight: 600;"
                            :style="hasScrolled ? { color: 'white' } : { color: 'black' }" class="nav-link active"
                            aria-current="page">{{ translate('about_us') }}</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto d-lg-inline-flex">
                    <li class="nav-item">
                        <div class="nav-link text-dark h5" style="position: relative;">
                            <router-link to="/login" class="btn btn-danger custom-btn-new">
                                <i style="font-size: 1.3em;" class="fa fa-user"></i> {{ translate('login') }}
                            </router-link>
                        </div>
                    </li>
                    <template v-if="hasScrolled">
                        <li class="nav-item dropdown" style="color: white;">
                        <select class="custom-select" name="lang" v-model="lang" @change="updateLang"
                            style="cursor: pointer; height: 50px; background-color: transparent; color: white; border: none; font-weight: bold;">
                            <option value="en" style="color: #CF0020;">EN</option>
                            <option value="fr" style="color: #CF0020;">FR</option>
                            <option value="nl" style="color: #CF0020;">NL</option>
                        </select>
                    </li>
                    </template>
                    <template v-else>
                        <li class="nav-item dropdown" style="color: white;">
                        <select class="custom-select" name="lang" v-model="lang" @change="updateLang"
                            style="cursor: pointer; height: 50px; background-color: transparent; color: black; border: none; font-weight: bold;">
                            <option value="en" style="color: #CF0020;">EN</option>
                            <option value="fr" style="color: #CF0020;">FR</option>
                            <option value="nl" style="color: #CF0020;">NL</option>
                        </select>
                    </li>
                    </template>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
    mixins: [en, fr, nl],
    data() {
        return {
            orders: [],
            hasScrolled: false,
            lang: this.$store.state.lang,
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);


        const language = navigator.language.split('-')[0];
        const supportedLanguages = ['en', 'fr', 'nl'];

        if (supportedLanguages.includes(language)) {
            this.lang = language;
        } else {
            this.lang = 'en';
        }

        this.updateLang()

    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        },
        updateLang() {
            this.$store.commit('setLang', this.lang);
        },
        handleScroll() {
            this.hasScrolled = window.pageYOffset > 0;
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('removeAgent')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        }
    }
}
</script>




<style>
.custom-nav-item:hover {
    text-decoration: underline #CF0020 4px;

}
.custom-select1 {
    border: none;
    font-weight: 600;
    background: transparent;
}

.custom-select2 {
    border: none;
    font-weight: 600;
    background: transparent;
    color: white;
}

.red-background {
    color: white;
    border-bottom: none;
    padding-bottom: 0px;
    position: fixed;
}

.text-color-white {
    color: white;
}

.text-color-black {
    color: black;
}
</style>
