<template>
    <div ref="scrollTarget"></div>
    <MainNav />

    <section class="laptop-section">
        <section style="margin-top: 80px; margin-bottom: 100px; padding-top: 85px">
            <div class="container text-center">
                <div class="row" :style="{ backgroundImage: 'url(' + backgroundrow + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': '40%' }">
                    <p style="font-size: 2.5em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_header') }}
                    </p>
                    <p style="font-size: 1.1em; padding-left: 200px; padding-right: 200px; margin-top: 10px;">{{
                        translate('notre_flotte_desc') }}</p>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 2.2em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title1') }}
                    </p>

                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/moto.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/bike.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 2.2em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title2') }}
                    </p>

                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/fourgonnettes.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/camions.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 2.2em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title3') }}
                    </p>
                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/vehicule.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-column" style="height: 100%; margin-top: 20px;">
                            <div>
                                <img src="../assets/img/livraison.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 30px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 2.2em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title4') }}</p>
                    <div class="col-8" style="margin-top: 20px;">
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc1') }}</p>
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc2') }}</p>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="ipad-section">
        <section style="margin-top: 30px; margin-bottom: 100px; padding-top: 85px">
            <div class="container text-center">
                <div class="row" :style="{ backgroundImage: 'url(' + backgroundrow + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': '40%' }">
                    <p style="font-size: 1.9em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_header') }}
                    </p>
                    <p style="font-size: 1.1em; margin-top: 10px;">{{
                        translate('notre_flotte_desc') }}</p>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 2.2em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title1') }}
                    </p>
                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/moto.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/bike.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 1.9em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title2') }}
                    </p>

                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/fourgonnettes.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/camions.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 1.9em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title3') }}
                    </p>
                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/vehicule.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle1') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-flex flex-column border" style="height: 100%; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/livraison.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle2') }}
                                </span>
                                <br>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;">
                    <p style="font-size: 1.9em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title4') }}</p>
                    <div class="col-12" style="margin-top: 20px;">
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc1') }}</p>
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc2') }}</p>
                    </div>
                </div>
            </div>
        </section>
    </section>

    <section class="mobile-section">
        <section style="margin-top: 30px; margin-bottom: 100px; padding-top: 85px">
            <div class="container text-center">
                <div class="row" :style="{ backgroundImage: 'url(' + backgroundrow + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': '40%' }">
                    <p style="font-size: 1.8em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_header') }}
                    </p>
                    <p style="font-size: 1.1em; padding-left: 20px; padding-right: 20px; margin-top: 10px;">{{
                        translate('notre_flotte_desc') }}</p>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;" :style="{ backgroundImage: 'url(' + graybackground + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': 'cover' }">
                    <p style="font-size: 1.8em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title1') }}
                    </p>

                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/moto_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle1') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 50px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/bike_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title1_soustitle2') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title1_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;" :style="{ backgroundImage: 'url(' + graybackground + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': 'cover' }">
                    <p style="font-size: 1.8em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title2') }}
                    </p>

                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/fourgonnettes_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle1') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 50px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/camions_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title2_soustitle2') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title2_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px;" :style="{ backgroundImage: 'url(' + graybackground + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': 'cover' }">
                    <p style="font-size: 1.8em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title3') }}
                    </p>
                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 20px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/vehicule_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle1') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle1_desc') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="d-flex flex-column border" style="padding-bottom: 15px; margin-top: 50px; border-radius: 20px;">
                            <div>
                                <img src="../assets/img/livraison_mobile.jpg">
                            </div>
                            <div style="padding: 5px; text-align: center; flex-grow: 1; margin-top: 20px;">
                                <span style="color: black; font-weight: 400; font-size: large;">
                                    {{ translate('notre_flotte_title3_soustitle2') }}
                                </span>
                                <br>
                                <span>{{ translate('notre_flotte_title3_soustitle2_desc') }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center text-center" style="margin-top: 60px; padding-bottom: 20px;" :style="{ backgroundImage: 'url(' + redbackground + ')', 'background-repeat': 'no-repeat', 'background-position': 'center top', 'background-size': 'cover' }">
                    <p style="font-size: 1.8em; color: #CF0020; font-weight: 400;">{{ translate('notre_flotte_title4') }}</p>
                    <div class="col-12" style="margin-top: 20px;">
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc1') }}</p>
                        <p style="color: black;">{{ translate('notre_flotte_title4_desc2') }}</p>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import MainNav from '@/components/MainNav2.vue';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';
import BackgroundRow from '../assets/img/background-row.svg';
import GrayBackground from '../assets/img/gray_baclground.jpg';
import RedBackground from '../assets/img/redbackground.jpg';

export default {
    mixins: [en, fr, nl],
    components: {
        MainNav
    },
    data() {
        return {
            lang: this.$store.state.lang,
            backgroundrow : BackgroundRow,
            graybackground : GrayBackground,
            redbackground : RedBackground
        }
    },
    computed: {
        lang2() {
            return this.$store.state.lang
        }
    },
    mounted() {
        document.title = 'CITExpress | Notre flotte';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });


    },
    methods: {
        translate(prop) {
            return this[this.lang2][prop];
        }
    }
}
</script>